module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CICOVI","short_name":"CICOVI","description":"Basés en Languedoc, nous proposons un service de conseil technique viticole. Notre cabinet est totalement indépendant. Nos consultants, ingénieurs agronomes-œnologues spécialisés en viticulture, réalisent un suivi personnalisé de votre vignoble afin de répondre aux objectifs technico économiques que vous vous êtes fixés.","start_url":"/","background_color":"#FAF9F5","theme_color":"#689A3D","display":"standalone","icon":"src/images/fav.png","lang":"fr","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f269377d77756f1ea67a6349575b9045"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"]},
    }]
